import PropTypes from 'prop-types'
import { normalizeProject } from 'models/Project'
import { CustomRule, customRuleShape } from './CustomRule'
import {
  BiDockPlatform,
  ProductExclusiveSources,
} from 'apollo/generated/globalTypes'
import type { Feed } from './Feed'
import type { Brand } from './Brand'
import type { Category } from './Category'
import type { View } from './View'
import type { GetProductCategories_user_product_categories } from 'apollo/generated/GetProductCategories'
import type { GetUserData_user_client_brands_products } from 'apollo/generated/GetUserData'
import type { GetPresetViews_presetViews } from 'apollo/generated/GetPresetViews'
import type { UpdateProduct_updateProduct } from 'apollo/generated/UpdateProduct'
import type {
  UserDataQuery_user_client_brands,
  UserDataQuery_user_client_brands_products,
} from 'apollo/generated/UserDataQuery'
import type { CreateListening_createNewListening } from 'apollo/generated/CreateListening'
import type { GetReviewProductLocations_getReviewProductLocations } from 'apollo/generated/GetReviewProductLocations'
import { GetProductCustomRulesCategories_user_product_categories } from 'apollo/generated/GetProductCustomRulesCategories'

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

// TODO: this enum use lowercase but ProductType from apollo use uppercase
// we should use the same type, in my opinion, ProductType from apollo is the right one
// product type api is converted to lowercase here src\store\selectors\productSidebarSelector.js
export enum ProductType {
  EXPLORE = 'explore',
  RACE = 'race',
  DRIVE = 'drive',
  MEDIA = 'media',
  LISTEN = 'listen',
  MAPS = 'maps',
  REVIEWS = 'review',
  REVIEWS_BENCHMARK = 'review_benchmark',
}

/* Get exclusive product type */
export const productTypeMap = (product): ProductType => {
  if (
    product.type === ProductType.RACE &&
    product.exclusiveSources?.length === 1 &&
    product.exclusiveSources[0] === ProductExclusiveSources.MEDIA
  ) {
    return ProductType.MEDIA
  }

  return product.type
}

export type ProductFormFields = {
  name: string
}

export type ProductShape = {
  id: string
  slug: string
  type: ProductType
  flavour?: string
  activeSources: string[]
  name?: string
  webhook: {
    v?: number
    __typename?: string
    enabled?: boolean
    internal?: boolean
    url?: string
  }
  exclusiveSources: string[]
  customRules?: CustomRule[]
  status: string
  brand: Brand
  feeds: Feed[]
  categories?: Category[]
  views?: View[]
  view?: View
}

export type TypeOfProduct = Array<ProductType> | ProductType

// Shapes
export const productShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'drive',
    'race',
    'maps',
    'explore',
    'review',
    'RACE',
    'DRIVE',
  ]).isRequired,
  flavour: PropTypes.string,
  activeSources: PropTypes.arrayOf(PropTypes.string),
  webhook: PropTypes.shape({
    enable: PropTypes.bool,
    url: PropTypes.string,
  }),
  exclusiveSources: PropTypes.arrayOf(PropTypes.string),
  customRules: PropTypes.arrayOf(customRuleShape),
})

export const normalizePresetViews = (input: GetPresetViews_presetViews) => {
  return {
    markets: (input?.markets || []).map(market => ({
      label: capitalizeFirstLetter(market.name.toLowerCase()),
      value: market.id,
    })),
    topics: (input?.topics || []).map(topic => ({
      label: capitalizeFirstLetter(topic.name.toLowerCase()),
      value: topic.name,
    })),
  }
}

export type PresetViews = ReturnType<typeof normalizePresetViews>

export const normalizeProductCategory = (
  input:
    | GetProductCategories_user_product_categories
    | GetProductCustomRulesCategories_user_product_categories,
) => ({
  id: input?.id || '',
  name: input?.name || '',
  description: input?.description || '',
  isExtended: input?.isExtended || false,
  isMarket: input?.isMarket || false,
  keywords: input.keywords.map(keyword => ({
    words: keyword.words ? keyword.words.map(word => word || '') : [],
    operator: keyword?.operator || '',
  })),
  riskLevel: input?.riskLevel || 0,
  originalCategoryName:
    (input as GetProductCategories_user_product_categories)
      ?.originalCategoryName || '',
  source: (input as GetProductCategories_user_product_categories)?.source || '',
})

type ProductInput =
  | CreateListening_createNewListening
  | GetUserData_user_client_brands_products
  | UserDataQuery_user_client_brands_products
  | UpdateProduct_updateProduct

const defaultBiDockConfiguration = {
  enabled: false,
  url: '',
  timezone: '',
  exportEveryMinutes: 60 * 24,
  platforms: {
    [BiDockPlatform.powerbi]: false,
    [BiDockPlatform.looker]: false,
    [BiDockPlatform.tableau]: false,
  },
  platformsList: [],
  credentials: {
    database: '',
    password: '',
    schema: '',
    username: '',
    host: '',
    port: 1234,
    ssl: false,
  },
}

export const normalizeReviewProductLocation = (
  input: GetReviewProductLocations_getReviewProductLocations,
) => ({
  name: input?.name || '',
  locationName: input?.locationName || '',
  address: {
    addressLines:
      (input?.address?.addressLines && [input.address.addressLines]) || [],
    administrativeArea: input?.address?.administrativeArea || '',
    locality: input?.address?.locality || '',
    postalCode: input?.address?.postalCode || '',
    regionCode: input?.address?.regionCode || '',
  },
})

export type ProductLocation = ReturnType<typeof normalizeReviewProductLocation>

export const normalizeProduct = (input: ProductInput) => {
  return {
    id: input?.id || '',
    slug: input?.slug || '',
    type: input?.type || ProductType.DRIVE,
    name: input?.name || '',
    webhook: {
      v: input?.webhook?.v || '',
      enabled: input?.webhook?.enabled || false,
      internal: input?.webhook?.internal || false,
      url:
        (input?.webhook && 'url' in input?.webhook && input?.webhook?.url) ||
        '',
    },
    biDockConfig:
      ('biDockConfig' in input && {
        enabled: input?.biDockConfig?.enabled || false,
        url: input?.biDockConfig?.url || '',
        timezone: input?.biDockConfig?.timezone || '',
        exportEveryMinutes: input?.biDockConfig?.exportEveryMinutes || 60 * 24,
        platforms: {
          [BiDockPlatform.powerbi]: input?.biDockConfig?.platforms?.includes(
            BiDockPlatform.powerbi,
          ),
          [BiDockPlatform.looker]: input?.biDockConfig?.platforms?.includes(
            BiDockPlatform.looker,
          ),
          [BiDockPlatform.tableau]: input?.biDockConfig?.platforms?.includes(
            BiDockPlatform.tableau,
          ),
        },
        platformsList: input?.biDockConfig?.platforms || [],
        credentials: {
          database: input?.biDockConfig?.credentials?.database || '',
          password: input?.biDockConfig?.credentials?.password || '',
          schema: input?.biDockConfig?.credentials?.schema || '',
          username: input?.biDockConfig?.credentials?.username || '',
          host: input?.biDockConfig?.credentials?.host || '',
          port: input?.biDockConfig?.credentials?.port || 1234,
          ssl: input?.biDockConfig?.credentials?.ssl || false,
        },
      }) ||
      defaultBiDockConfiguration,
    exclusiveSources: input?.exclusiveSources || [],
    customRules: ('customRules' in input && input?.customRules) || [],
    status: input?.status || '',
    brand:
      ('brand' in input &&
        normalizeProject(input.brand as UserDataQuery_user_client_brands)) ||
      null,
    feeds: ('feeds' in input && input?.feeds) || [],
    categories: ('categories' in input && input?.categories) || [],
    views: ('views' in input && input?.views) || [],
  }
}

export type Product = ReturnType<typeof normalizeProduct>
